import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import sustenibilitystyle from '../styles/sustenibility.module.css';
import generic from '../styles/question.module.css';

import useStorage from '../../../hooks/useStorage';

import { useRouter } from 'next/router';

const Intro = ({ nobutton = false, backtoresults = false, gotoresults = false }) => {
  const { t } = useTranslation();
  const router = useRouter();

  const [error, setError] = useState(null);
  const { getItem, getItems, setItem } = useStorage();
  const [selectedType, setSelectedType] = useState(getItem('product_type', 'local'));

  useEffect(() => {
    setSelectedType(getItem('product_type', 'local'));
  }, []);

  function formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  function successPercentage(rata, entrate) {
    const percentage = (rata / entrate) * 100;
    let level = 'alta';
    if (percentage > 30) {
      level = 'media';
    }
    if (percentage > 50) {
      level = 'bassa';
    }
    return { percentage, level };
  }

  // const stepValue = getItem('step_validate_1', 'local') === '1' ? 'step_validate_2' : 'step_validate_3';
  const stepValue = getItem('step_19', 'local') === '1' ? 'step_20' : 'step_21';

  // console.log('stepValue', stepValue);

  const products = {
    [`${getItem('product_type', 'local')}`]: {
      rata: `${getItem('product_rata', 'local')}`,
      tan: `${getItem('product_tan', 'local')}`,
      success: successPercentage(
        Number(getItem('product_rata', 'local').replace('.', '').replace(',', '.')),
        Number(getItem(stepValue, 'local').replace('.', '').replace(',', '.')),
      ),
    },
  };

  if (getItem('product_other_type', 'local').trim().length > 0) {
    products[`${getItem('product_other_type', 'local')}`] = {
      rata: `${getItem('product_other_rata', 'local') || 0}`,
      tan: `${getItem('product_other_tan', 'local') || 0}`,
      success: successPercentage(
        Number(getItem('product_other_rata', 'local').replace('.', '').replace(',', '.') || 0),
        Number(getItem(stepValue, 'local').replace('.', '').replace(',', '.') || 0),
      ),
    };
  }

  function suggestion(level) {
    let ret = <div>sssss</div>;
    switch (level) {
      case 'alta':
        ret = (
          <div className={cn(sustenibilitystyle.suggestion)}>
            <div className={cn(sustenibilitystyle.imgContainer)}>
              <img className={cn(sustenibilitystyle.suggestionImg)} src="/sost_alta.png" alt="Alta sostenibilità" />
            </div>
            <div>
              Dai dati inseriti la tua pratica di Mutuo ha un alta solvibilità. <b>PARLA</b> con uno dei nostri esperti, gratuitamente e senza
              impegno, in modo da configurare la soluzione di mutuo per te più vantaggiosa.
            </div>
          </div>
        );
        break;
      case 'media':
        ret = (
          <div className={cn(sustenibilitystyle.suggestion)}>
            <div className={cn(sustenibilitystyle.imgContainer)}>
              <img className={cn(sustenibilitystyle.suggestionImg)} src="/sost_media.png" alt="Media sostenibilità" />
            </div>
            <div>
              <b>È IMPORTANTE PER TE</b> parlare con uno dei nostri consulenti specializzati e verificare come configurare l’operazione di mutuo.
            </div>
          </div>
        );
        break;
      case 'bassa':
        ret = (
          <div className={cn(sustenibilitystyle.suggestion)}>
            <div className={cn(sustenibilitystyle.imgContainer)}>
              <img className={cn(sustenibilitystyle.suggestionImg)} src="/sost_bassa.png" alt="Bassa sostenibilità" />
            </div>
            <div>
              <b>È NECESSARIO</b> che tu approfondisca con uno dei nostri consulenti specializzati e verificare come poter riuscire ad accedere ad
              un’operazione di mutuo.
            </div>
          </div>
        );
        break;
    }
    return ret;
  }

console.log(products);

  return (
    <div className={cn('sust_container', sustenibilitystyle.container)}>
      {/* <div className={cn(sustenibilitystyle.leftCol, sustenibilitystyle.col)}>{suggestion(products[selectedType].success.level)}</div> */}
      <div className={cn('sust_rightCol', sustenibilitystyle.rightCol, sustenibilitystyle.col)}>
        <div className={cn(sustenibilitystyle.infoContainer)}>
          <div className={cn(sustenibilitystyle.switchContainer)}>
            {products['fisso'] !== undefined ? (
              <div
                className={cn(sustenibilitystyle.switchButton, sustenibilitystyle[`${selectedType === 'fisso' ? 'active' : 'inactive'}`])}
                onClick={() => {
                  setSelectedType('fisso');
                }}>
                FISSO
              </div>
            ) : null}
            {products['variabile'] !== undefined ? (
              <div
                className={cn(sustenibilitystyle.switchButton, sustenibilitystyle[`${selectedType === 'variabile' ? 'active' : 'inactive'}`])}
                onClick={() => {
                  setSelectedType('variabile');
                }}>
                VARIABILE
              </div>
            ) : null}
          </div>

          <div className={cn(sustenibilitystyle.details)}>
            <div className={cn(sustenibilitystyle.detailsContent, sustenibilitystyle.detailsR)}>
              <b>RATA MENSILE:</b>
              <br />
              <span className={cn(sustenibilitystyle.value)}>{products[selectedType].rata}</span>
              <span className={cn(sustenibilitystyle.um)}>€</span>
            </div>
            <div className={cn(sustenibilitystyle.detailsContent, sustenibilitystyle.detailsL)}>
              <b>TASSO:</b>
              <br />
              <span className={cn(sustenibilitystyle.value)}>{products[selectedType].tan}</span>
              <span className={cn(sustenibilitystyle.um)}>%</span>
            </div>
          </div>

          <div className={cn(sustenibilitystyle.detailssustenibility)}>
            <div className={cn(sustenibilitystyle.detailssustenibilityContent, sustenibilitystyle.detailssustenibilityR)}>
              <b>Sostenibilità rata:</b>
              <div className={cn(sustenibilitystyle.value)}>
                <span className={cn(sustenibilitystyle.semaforo, sustenibilitystyle[products[selectedType].success.level])}>
                  {products[selectedType].success.level}
                </span>
              </div>
            </div>
            <div className={cn(sustenibilitystyle.detailssustenibilityContent, sustenibilitystyle.detailssustenibilityL)}>
              <b>Impatto redditi:</b>
              <div className={cn(sustenibilitystyle.value)}>
                <span className={cn(sustenibilitystyle.sustenibilityPerc, sustenibilitystyle[products[selectedType].success.level])}>
                  {products[selectedType].success.percentage.toFixed(2).replace('.', ',')}
                </span>
                <span className={cn(sustenibilitystyle.um, sustenibilitystyle[products[selectedType].success.level])}>%</span>
              </div>
            </div>
          </div>

          <div className={cn(sustenibilitystyle.suggestionMobile)}>{suggestion(products[selectedType].success.level)}</div>
          {!nobutton ? (
            <div className={cn(generic.stepButton, sustenibilitystyle.nextStep)}>
              <button
                onClick={() => {
                  router.push(`/verifica-fattibilita/`);
                }}
                className={cn(generic.nextButton)}>
                {'PROSEGUI'}
                <span className={cn(generic.nextButtonCross)}>{' >>'}</span>
              </button>
            </div>
          ) : null}
          {backtoresults ? (
            <div className={cn(generic.stepButton, sustenibilitystyle.nextStep)}>
              <button
                onClick={() => {
                  router.push('/preventivi-mutuo');
                }}
                className={cn(generic.nextButton)}>
                {'TORNA AI RISULTATI'}
                <span className={cn(generic.nextButtonCross)}>{' >>'}</span>
              </button>
            </div>
          ) : null}
          {gotoresults ? (
            <div className={cn(generic.stepButton, sustenibilitystyle.nextStep)}>
              <button
                onClick={() => {
                  router.push('/preventivi-mutuo');
                }}
                className={cn(generic.nextButton)}>
                {'MOSTRA I POSSIBILI MUTUI'}
                <span className={cn(generic.nextButtonCross)}>{' >>'}</span>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Intro;

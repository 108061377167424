import React from 'react';
import cn from 'classnames';
import styles from './styles/CustomTextInput.module.css';
const CustomTextInput = ({
  label,
  type = 'text',
  value,
  onChange,
  errorMessage = null,
  showCurrencyIcon = false,
  showPercentageIcon = false,
  styleInput = {},
  contentContainerStyle = {},
  isNumber = false,
  id,
  placeholder = '',
  disabled = false,
  ...other
}) => {
  const hasAddon = showCurrencyIcon || showPercentageIcon;
  const getInputMode = () => {
    if (hasAddon || type === 'number') return 'decimal';
    if (type === 'tel') return 'tel';
    if (type === 'email') return 'email';
    return 'text';
  };
  return (
    <div className={cn(styles.inputContainer, errorMessage && styles.error)} style={contentContainerStyle}>
      {label && <label htmlFor={id}>{label}</label>}
      <div
        className={cn({
          [styles.valueInputContainer]: hasAddon,
        })}>
        <input
          id={id}
          type={type}
          value={value}
          onChange={onChange}
          inputMode={getInputMode()}
          placeholder={placeholder}
          disabled={disabled}
          className={cn({
            value_input: hasAddon,
            [styles.errorInput]: errorMessage,
          })}
          style={styleInput}
          aria-invalid={!!errorMessage}
          aria-describedby={errorMessage ? `${id}-error` : undefined}
          {...other}
        />
        {hasAddon && (
          <span className={styles.valueInputCurrency} style={styleInput}>
            {showCurrencyIcon && '€'}
            {showPercentageIcon && '%'}
          </span>
        )}
      </div>
      {errorMessage && (
        <span id={`${id}-error`} className={styles.errorMessage} role="alert">
          {errorMessage}
        </span>
      )}
    </div>
  );
};
CustomTextInput.defaultProps = {
  type: 'text',
  errorMessage: null,
  showCurrencyIcon: false,
  showPercentageIcon: false,
  styleInput: {},
  contentContainerStyle: {},
  isNumber: false,
  placeholder: '',
  disabled: false,
};
export default CustomTextInput;

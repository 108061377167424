import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import CustomSelectInput from '../CustomSelectInput';
import styles from '../styles/select.module.css';
import generic from '../styles/question.module.css';
const Question = ({ question, next, prev, answers, updateAnswer }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const validateSelection = useCallback(() => {
    if (question.required && !answers[question.step]) {
      setError(t('form.errors.select_required', 'Seleziona un valore'));
      return false;
    }
    setError(null);
    return true;
  }, [question.required, question.step, answers, t]);
  const handleChange = useCallback(
    e => {
      const selectedValue = e.value;
      updateAnswer(question.step, selectedValue, question.var_name);
      setError(null);
      next(question.last_question, question.step, selectedValue);
    },
    [question.step, question.var_name, question.last_question, next, updateAnswer],
  );
  const handleNext = useCallback(() => {
    if (validateSelection()) {
      next(question.last_question, question.step, answers[question.step]);
    }
  }, [validateSelection, question.last_question, question.step, answers, next]);
  const handlePrev = useCallback(() => {
    setError(null);
    prev();
  }, [prev]);
  return (
    <div className={cn(styles.select)} role="form" aria-label={t('form.select.label', 'Selection form')}>
      <div id={`question-label${question.var_name}`} className={cn(generic.question_label)} >
        {question.question_label}
      </div>
      <CustomSelectInput
        label=""
        value={answers[question.step]}
        onChange={handleChange}
        errorMessage={error}
        options={question.question_answers}
        aria-labelledby={`question-label-${question.step}`}
        aria-required={question.required}
        aria-invalid={!!error}
        ref={null}
      />
      {question.suggestion && (
        <div id={`question-suggestion${question.var_name}`} className={cn(generic.suggestion)} role="note">
          {question.suggestion}
        </div>
      )}
      <div className={cn(generic.stepButton)} role="navigation">
        {question.step > 0 && (
          <button onClick={handlePrev} className={cn(generic.prevButton)} aria-label={t('form.previous', 'Previous step')}>
            <span aria-hidden="true">{'<<'}</span>
          </button>
        )}
        <button onClick={handleNext} className={cn(generic.nextButton)} aria-label={t('form.next', 'Next step')}>
          {t('form.next.label', 'AVANTI')}
          <span className={cn(generic.nextButtonCross)} aria-hidden="true">
            {' >>'}
          </span>
        </button>
      </div>
      {error && (
        <div role="alert" className="error-message" style={{ color: 'red', marginTop: '8px' }}>
          {error}
        </div>
      )}
    </div>
  );
};
Question.defaultProps = {
  question: {
    step: 0,
    required: false,
    question_label: '',
    question_answers: [],
    suggestion: '',
    last_question: false,
    var_name: '',
  },
  answers: {},
  next: () => {},
  prev: () => {},
  updateAnswer: () => {},
};
export default Question;

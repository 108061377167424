import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from '../styles/select.module.css';
import generic from '../styles/question.module.css';
import CustomTextInput from '../CustomTextInput.js';
const CURRENCY_REGEX = /^[0-9]*\.*[0-9]*\.*[0-9]*\.*[0-9]*\.*[0-9]*\.*[0-9]*\.*[0-9]*$/;
const Question = ({ question, next, prev, answers, updateAnswer }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const formatNumber = useCallback(value => {
    if (!value) return '';
    return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }, []);
  const validateInput = useCallback(value => {
    return CURRENCY_REGEX.test(value);
  }, []);
  const validateAmount = useCallback(() => {
    const currentValue = answers[question.step];
    if (!currentValue) {
      if (question.required) {
        setError(t('form.errors.required', 'Inserisci un valore'));
        return false;
      }
      return true;
    }
    const numericValue = Number(currentValue.replaceAll('.', ''));
    if (!numericValue > 0) {
      setError(t('form.errors.positive', 'Inserisci un valore positivo'));
      return false;
    }
    if (question.min_value) {
      const minValue = question.min_value === 'prevValue' ? Number(answers[question.step - 1]?.replaceAll('.', '')) : Number(question.min_value);
      if (numericValue < minValue) {
        setError(t('form.errors.min_value', `Il valore deve essere superiore a ${formatNumber(String(minValue))}€`));
        return false;
      }
    }
    if (question.max_value && numericValue > Number(question.max_value)) {
      setError(t('form.errors.max_value', `Il valore deve essere inferiore a ${formatNumber(question.max_value)}€`));
      return false;
    }
    setError(null);
    return true;
  }, [answers, question, formatNumber, t]);
  const calculatePercentage = useCallback(() => {
    const loanAmount = Number(answers[question.step]?.replaceAll('.', ''));
    const propertyCost = Number(answers[question.step - 1]?.replaceAll('.', ''));

    if (loanAmount && propertyCost) {
      const calculatedPercentage = ((loanAmount / propertyCost) * 100).toFixed(2);
      const percentageValue = parseFloat(calculatedPercentage);
      if (percentageValue > 100) {
        setError(t('form.errors.percentage_exceeds', 'La percentuale non può superare il 100%.'));
        setPercentage(percentageValue);
      } else {
        setPercentage(percentageValue);
        setError(null);
      }
    } else {
      setPercentage(null);
      setError(null);
    }
  }, [answers, question, t]);
  useEffect(() => {
    calculatePercentage();
  }, [answers[question.step], calculatePercentage]);

  const handleInputChange = e => {
    const { value } = e.target;
    if (validateInput(value)) {
      updateAnswer(question.step, value, question.var_name);
    }
  };
  const handleNext = () => {
    if (percentage <= 100 && validateAmount()) {
      next(question.last_question, question.step);
    }
  };

  const handlePrev = () => {
    setError(null);
    prev();
  };
  const getPlaceholder = useCallback(() => {
    if (question.step !== 1 || !answers[1]) return question.question_placeholder;
    const previousValue = Number(answers[1].replaceAll('.', ''));
    if (!previousValue) return question.question_placeholder;
    return `es ${formatNumber(String(previousValue * 1.2))}`;
  }, [question, answers, formatNumber]);

  return (
    <div className={cn(styles.select)}>
      <div id={`question-label${question.var_name}`} className={cn(generic.question_label)}>
        {question.question_label}
        {question.nofear && (
          <div className="nofear">
            <span style={{ color: 'rgb(46 153 67)', fontWeight: 'bold' }}>{t('form.nofear.title', 'Niente paura!')}</span> {question.nofear}
          </div>
        )}
      </div>
      <CustomTextInput
        label=""
        type="text"
        showCurrencyIcon
        contentContainerStyle={question.nofear ? { minHeight: '180px' } : {}}
        placeholder={getPlaceholder()}
        step="1000"
        value={answers[question.step] ? formatNumber(answers[question.step]) : ''}
        className="compact_custom_input"
        onChange={handleInputChange}
        onKeyDown={e => {
          if (e.key === 'Enter') handleNext();
        }}
        isNumber={true}
        errorMessage={error}
        ref={null}
      />
      {percentage !== null && (
        <div className={cn(generic.percentageDisplay, generic.percentage)}>
          <strong>{percentage}%</strong>&nbsp;{t('form.percentage', 'della casa')}
        </div>
      )}
      {question.suggestion && (
        <div id={`question-suggestion${question.var_name}`} className={cn(generic.suggestion)} role="note">
          {question.suggestion}
        </div>
      )}
      <div className={cn(generic.stepButton)}>
        {question.step > 0 && (
          <button onClick={handlePrev} className={cn(generic.prevButton)} aria-label={t('form.previous', 'Previous')}>
            {'<<'}
          </button>
        )}
        <button
          onClick={handleNext}
          className={cn(generic.nextButton, { [generic.disabledButton]: !!error || percentage > 100 })}
          aria-label={t('form.next', 'Next')}
          disabled={!!error || percentage > 100}>
          {t('form.next.label', 'AVANTI')}
          <span className={cn(generic.nextButtonCross)} aria-hidden="true">
            {' >>'}
          </span>
        </button>
      </div>
    </div>
  );
};
export default Question;

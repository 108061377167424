import React, { useState } from 'react';
import styles from './styles/CityAutocompleteInput.module.css';
import axios from 'axios';
import config from '../../config/config';
import cn from 'classnames';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
const CityAutocompleteInput = ({ label = 'Città', value, className = '', onChange, errorMessage = null, contentContainerStyle = {}, id }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(value);
  const fetchPlace = async text => {
    if (!text?.trim()) return [];
    try {
      const { baseUrl } = config;
      const endpoint = `${baseUrl}/v1/city/autocomplete?city=${encodeURIComponent(text)}`;
      const res = await axios.get(endpoint);
      return res.data.resultsCities;
    } catch (err) {
      console.error('Error fetching cities:', err);
      return [];
    }
  };
  const loadOptions = async (inputValue, callback) => {
    const results = await fetchPlace(inputValue);
    const options = results.map(city => ({
      label: city.nome,
      value: city,
    }));
    callback(options);
  };
  const customStyles = {
    control: styles => ({
      ...styles,
      borderRadius: '0px',
      minWidth: '260px',
      borderColor: '#707070',
      minHeight: '55px',
    }),
    input: styles => ({
      ...styles,
      fontSize: '23px',
      padding: '10px 20px',
      color: 'rgb(117, 117, 117)',
    }),
    singleValue: styles => ({
      ...styles,
      fontSize: '23px',
      padding: '10px 20px',
      color: 'rgb(117, 117, 117)',
    }),
    option: styles => ({ ...styles }),
  };
  return (
    <div className={cn(styles.inputContainer, errorMessage && styles.error)} style={contentContainerStyle}>
      <label htmlFor={id}>{label}</label>
      <AsyncSelect
        id={id}
        cacheOptions
        defaultOptions={value ? [{ value, label: value }] : []}
        className={cn(styles.select)}
        loadOptions={loadOptions}
        value={value ? { value, label: value } : null}
        inputValue={inputValue}
        onInputChange={setInputValue}
        onChange={onChange}
        styles={customStyles}
        noOptionsMessage={() => (inputValue ? t('preventivatore.no_city_message') : t('preventivatore.search_city_message'))}
      />
      {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
    </div>
  );
};
export default CityAutocompleteInput;

import React from 'react';
import cn from 'classnames';
import styles from './styles/CustomSelectInput.module.css';
import Select from 'react-select';
const CustomSelectInput = ({
  label,
  value,
  onChange,
  options = [],
  className = '',
  errorMessage = null,
  id = 'idSelectInput',
  contentContainerStyle = {},
  ...other
}) => {
  const customStyles = {
    control: baseStyles => ({
      ...baseStyles,
      borderRadius: '0px',
      minWidth: '260px',
      borderColor: errorMessage ? '#dc3545' : '#707070',
      minHeight: '55px',
      '&:hover': {
        borderColor: errorMessage ? '#dc3545' : '#505050',
      },
    }),
    input: baseStyles => ({
      ...baseStyles,
      fontSize: '23px',
      padding: '10px 20px',
      color: 'rgb(117, 117, 117)',
    }),
    singleValue: baseStyles => ({
      ...baseStyles,
      fontSize: '23px',
      padding: '10px 20px',
      color: 'rgb(117, 117, 117)',
    }),
    option: (baseStyles, { isSelected, isFocused }) => ({
      ...baseStyles,
      backgroundColor: isSelected ? '#007bff' : isFocused ? '#f8f9fa' : 'white',
      color: isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: isSelected ? '#007bff' : '#f0f0f0',
      },
    }),
  };
  const selectedOption = value
    ? {
        value,
        label: options?.find(opt => opt.value === value)?.label || value,
      }
    : null;
  return (
    <div className={cn(styles.inputContainer, errorMessage && styles.error, className)} style={contentContainerStyle}>
      {label && <label htmlFor={id}>{label}</label>}
      <Select
        {...other}
        id={id}
        className={cn(styles.select)}
        styles={customStyles}
        onChange={onChange}
        options={options}
        isSearchable={false}
        value={selectedOption}
        menuPlacement="auto"
      />
      {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
    </div>
  );
};
export default CustomSelectInput;

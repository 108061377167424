import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from '../styles/select.module.css';
import introstyle from '../styles/intro.module.css';
import generic from '../styles/question.module.css';
import useStorage from '../../../hooks/useStorage';
import getPreventivo from '../../../api/getPreventivo.js';
const Intro = ({ question, next, prev }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const { getItem, setItem } = useStorage();
  const formatNumber = number => {
    if (!number) return '0';
    return number.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };
  const fetchPreventivo = async (tipoTasso, isAlternative = false) => {
    try {
      const response = await getPreventivo({
        importo: getItem('step_0', 'local')?.replaceAll('.', ''), // replaced steps here!
        valoreImmobile: getItem('step_1', 'local')?.replaceAll('.', ''),
        tipoTasso,
        finalita: getItem('step_5', 'local'),
        durata: getItem('step_4', 'local'),
        ordina: 'PerRata',
        eta: getItem('step_2', 'local'),
      });
      if (response?.preventivi) {
        const prefix = isAlternative ? 'product_other_' : 'product_';
        const betterResponse = response.preventivi[Object.keys(response.preventivi)[0]][0];
        setItem(`${prefix}banca`, betterResponse.nomebanca, 'local');
        setItem(`${prefix}rata`, betterResponse.rata, 'local');
        setItem(`${prefix}tan`, betterResponse.tan, 'local');
        setItem(`${prefix}type`, betterResponse.tipotasso.toLowerCase(), 'local');
        if (!isAlternative) {
          setItem('validity', true, 'local');
        }
      }
    } catch (error) {
      console.error('Error fetching preventivo:', error);
    }
  };
  useEffect(() => {
    const currentTasso = getItem('step_3', 'local');
    const alternativeTasso = currentTasso === 'fisso' ? 'variabile' : 'fisso';
    fetchPreventivo(currentTasso);
    fetchPreventivo(alternativeTasso, true);
  }, []);
  const handlePrev = () => {
    setError(null);
    prev();
  };
  const handleNext = () => {
    next(question.last_question, question.step);
  };
  return (
    <div className={cn(styles.select)}>
      <div className={cn(generic.question_label)}>
        <span>{question.question_label_1}</span>
        <span className={cn(introstyle.valInput)}>{`${formatNumber(getItem('step_0', 'local'))} €`}</span>
        <span>{question.question_label_2}</span>
        <span className={cn(introstyle.valInput)}>{`tasso ${getItem('step_3', 'local')}`}</span>
      </div>
      {question.introtext && <div id={`question-suggestion${question.var_name}`} className={cn(generic.suggestion)}>{question.introtext}</div>}
      <div className={cn(generic.stepButton)}>
        {question.step > 0 && (
          <button onClick={handlePrev} className={cn(generic.prevButton)} aria-label={t('navigation.previous', 'Previous')}>
            {'<<'}
          </button>
        )}
        <button onClick={handleNext} className={cn(generic.nextButton)} rel="nofollow" aria-label={t('navigation.next', 'Next')}>
          {t('navigation.forward', 'AVANTI')}
          <span className={cn(generic.nextButtonCross)} aria-hidden="true">
            {' >>'}
          </span>
        </button>
      </div>
      {question.suggestion && <div id={`question-suggestion${question.var_name}`} className={cn(generic.suggestion)}>{question.suggestion}</div>}
    </div>
  );
};
export default Intro;

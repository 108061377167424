import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from '../styles/select.module.css';
import introstyle from '../styles/intro.module.css';
import generic from '../styles/question.module.css';
const Intro = ({ question, next, prev }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const handlePrev = () => {
    setError(null);
    prev();
  };
  const handleNext = () => {
    next(question.last_question, question.step);
  };
  return (
    <section className={cn(styles.select)}>
      <div className={cn(generic.question_label)}>
        {question.question_label_1}
        <span className={cn(introstyle.valInput)} aria-label={t('amount.label', 'Amount in euros')}>
          {`200000 €`}
        </span>
        {question.question_label_2}
        <span className={cn(introstyle.valInput)} aria-label={t('rate.type.label', 'Rate type')}>
          {`tasso fisso`}
        </span>
        {question.question_label_3}
        <span className={cn(introstyle.valInput)} aria-label={t('bank.label', 'Bank name')}>
          {`bnl`}
        </span>
      </div>
      {question.introtext && (
        <div id={`question-suggestion${question.var_name}`} className={cn(generic.suggestion)} role="note">
          {question.introtext}
        </div>
      )}
      <div className={cn(generic.stepButton)} role="navigation">
        {question.step > 0 && (
          <button onClick={handlePrev} className={cn(generic.prevButton)} aria-label={t('navigation.previous', 'Previous step')}>
            <span aria-hidden="true">{'<<'}</span>
          </button>
        )}
        <button onClick={handleNext} className={cn(generic.nextButton)} aria-label={t('navigation.next', 'Next step')}>
          {t('navigation.forward', 'AVANTI')}
          <span className={cn(generic.nextButtonCross)} aria-hidden="true">
            {' >>'}
          </span>
        </button>
      </div>
      {question.suggestion && (
        <div id={`question-suggestion${question.var_name}`} className={cn(generic.suggestion)} role="note">
          {question.suggestion}
        </div>
      )}
    </section>
  );
};
Intro.defaultProps = {
  question: {
    step: 0,
    question_label_1: '',
    question_label_2: '',
    question_label_3: '',
    introtext: '',
    suggestion: '',
    last_question: false,
  },
  next: () => {},
  prev: () => {},
};
export default Intro;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import CityAutocompleteInput from '../CityAutocompleteInput';
import styles from '../styles/select.module.css';
import generic from '../styles/question.module.css';
import useStorage from '../../../hooks/useStorage.ts';
const Question = ({ question, next, prev, answers, updateAnswer }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const { setItem } = useStorage();
  const handleValidation = () => {
    if (question.required && !answers[question.step]) {
      setError(t('form.city.required', 'Seleziona una città'));
      return false;
    }
    setError(null);
    return true;
  };
  const handleCityChange = cityData => {
    const { regione, provincia, nome } = cityData.value;
    setItem(`step_${question.step}_regione`, regione, 'local');
    setItem(`step_${question.step}_provincia`, provincia, 'local');
    updateAnswer(question.step, nome, question.var_name);
    setError(null);
    // next(question.last_question, question.step, nome);
  };
  const handleNextClick = () => {
    if (handleValidation()) {
      next(question.last_question, question.step);
    }
  };
  const handlePrevClick = () => {
    setError(null);
    prev();
  };
  return (
    <div className={cn(styles.select)} role="group" aria-labelledby="city-question-label">
      <div id={`city-question-label${question.var_name}`} className={cn(generic.question_label)}>
        {question.question_label}
      </div>
      <CityAutocompleteInput
        label=""
        value={answers[question.step]}
        onChange={handleCityChange}
        errorMessage={error}
        options={question.question_answers}
        aria-invalid={!!error}
        aria-describedby={error ? 'city-error-message' : undefined}
      />
      {/* {question.suggestion && (
        <div id={`question-suggestion${question.var_name}`} className={cn(generic.suggestion)} role="note">
          {question.suggestion}
        </div>
      )} */}
      <div className={cn(generic.stepButton)}>
        {question.step > 0 && (
          <button onClick={handlePrevClick} className={cn(generic.prevButton)} aria-label={t('form.previous', 'Previous question')}>
            {'<<'}
          </button>
        )}
        <button
          onClick={handleNextClick}
          className={cn(generic.nextButton, { [generic.disabledButton]: !answers[question.step] })}
          ria-label={t('form.next', 'Next question')}
          disabled={!answers[question.step]}>
          {t('form.next.label', 'AVANTI')}
          <span className={cn(generic.nextButtonCross)} aria-hidden="true">
            {' >>'}
          </span>
        </button>
      </div>
    </div>
  );
};
export default Question;

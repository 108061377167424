import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './styles/stepper.module.css';
const Stepper = ({ total, current, intro = false, question = {} }) => {
  const { t } = useTranslation();
  const getPercentage = () => {
    const rawPercentage = question.percentage ? Number(question.percentage) : 0;
    return Math.min(Math.max(rawPercentage, 0), 100); 
  };
  const percentage = getPercentage();
  return (
    <div className={cn(styles.stepper, intro && styles.intro)} role="progressbar" aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100">
      <div className={styles.stepperContainer}>
        <div
          className={styles.stepperCompleted}
          style={{
            width: `${percentage}%`,
            transition: 'width 0.3s ease-in-out',
          }}
        />
      </div>
      <div className={styles.percentage}>{Math.round(percentage)}%</div>
    </div>
  );
};
Stepper.defaultProps = {
  intro: false,
  question: {},
  total: 0,
  current: 0,
};
export default Stepper;

import React from 'react';
import ReactTelInput from 'react-telephone-input';
import cn from 'classnames';
import styles from './styles/CustomTelephoneInput.module.css';
import { useTranslation } from 'react-i18next';
const CustomTelephoneInput = ({
  label,
  value,
  onChange,
  onChangePrefix,
  errorMessage = null,
  placeholder = '',
  initialValuePrefix = '+39',
  id = 'phone',
  disabled = false,
  ...other
}) => {
  const { t } = useTranslation();
  const telInputProps = {
    readOnly: true,
    autoFocus: false,
    autoComplete: 'off',
    id: `${id}-prefix`,
  };
  const listStyle = {
    zIndex: 99999999,
    backgroundColor: 'white',
    width: '100px',
    position: 'absolute',
  };
  return (
    <div className={cn(styles.inputContainer, styles.phoneInput, errorMessage && styles.error)}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className={cn(styles.prefixContainer, 'select-prefix-container')}>
        <link rel="stylesheet" type="text/css" href="/css/telephoneFlags.css" />

        <ReactTelInput
          defaultCountry="it"
          initialValue={initialValuePrefix}
          onChange={onChangePrefix}
          autoFormat
          inputProps={telInputProps}
          onBlur={() => {}}
          flagsImagePath="/flags.png"
          listStyle={listStyle}
          disabled={disabled}
          {...other}
        />
      </div>
      <input
        type="tel"
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={cn(styles.phoneNumber, errorMessage && styles.errorInput)}
        aria-invalid={!!errorMessage}
        aria-describedby={errorMessage ? `${id}-error` : undefined}
      />
      {errorMessage && (
        <span id={`${id}-error`} className={styles.errorMessage} role="alert">
          {errorMessage}
        </span>
      )}
    </div>
  );
};
export default CustomTelephoneInput;
